@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"























































































































































































































































































































































































































































































































$color-filter-box: $color-ice

.assessments-container
  .header
    border-bottom: 1px solid $color-ink-lightest

  .assessments
    padding: 16px 0px 16px 0px
    box-sizing: border-box
    color: $color-ink

  .assessments-types__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 12px 18px
    margin-top: 24px

    +mq-m
      font-size: $font-size-heading-4
      grid-template-columns: 1fr 1fr 1fr

    +mq-s
      grid-template-columns: 1fr 1fr
      font-size: $font-size-heading-5

    +mq-xs
      width: 100%
      grid-template-columns: none

    .assessment-type-card
      +mq-s
        height: 164px
      +mq-xs
        flex-flow: row
        height: 76px
        justify-content: end
        align-items: baseline

      .assessment-type-card__title
        +mq-xs
          font-size: $font-size-heading-6

  .assessment-filter-box
    background-color: $color-filter-box
    margin-top: 18px
    grid-template-columns: auto 1fr
    grid-gap: 10px
    display: grid

    .sas-button
      box-shadow: none

    .sas-button__text
      p
        font-weight: $font-weight-bold

    .sas-select
      flex-direction: column

    +mq-m
      grid-template-rows: 1fr 1fr
      grid-template-columns: none

    .assessment-filter-box-subgrid
      display: grid
      align-items: flex-end
      margin-top: 2px
      grid-template-columns: auto auto auto
      grid-gap: $size-s
      background-color: $color-filter-box

      .icon-img
        background: url('~@/assets/images/import-export.svg') no-repeat
      +mq-m
        grid-template-columns: auto 35px
      +mq-s
        font-size: 14px
      +mq-xs
        font-size: 12px

      .assessment-custom-button
        background-color: $color-primary-lightest

    .sas-dropdown-menu
      width: 100%

    .sas-dropdown
      +mq-m
        width: 100%

    .sas-dropdown__button
      min-width: 264px
      +mq-s
        min-width: 100%

    .assessment-order-custom
      align-self: end
      text-align: center

    .s-header-subtitle
      color: $color-ink-light

