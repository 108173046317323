@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































































































$font-text-color: #1E2124
$border-color-custom: rgba(0, 0, 0, 0.25)

::v-deep .drawer
  transition: transform $speed-fast ease-in-out !important
  height: initial
  max-height: 273px
  font-color: $font-text-color
  font-weight: $font-weight-semi-bold

  &.--position-bottom
    border-radius: 16px 16px 0 0

  &-header
    padding: 16px 0
    box-shadow: $shadow-s $border-color-custom
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    &__wrapper
      +flex-space-between

    &__title
      +flex-center-start

      img
        margin-right: $size-s

      p
        font-style: normal
        font-weight: 500
        font-size: $font-size-heading-5
        line-height: 28px

  &-footer
    padding: $size-s 0
    box-shadow: 0px 0px 7px $border-color-custom
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    .drawer-custom-button
        width: 100%

  &-content
    padding: $size-m 0

    .subgrid
      margin-top: 10px
      margin-bottom: $size-s
      grid-template-columns: 25px 1fr
      display: grid
      grid-gap: 2px

      label
        font-size: $font-size-m
        line-height: $size-m
        font-color: $color-ink

      .checkmark
        width: 20px
        height: 20px

    & > div
      margin-bottom: 28px

    +mq-xl
      padding-left: $size-s
      padding-right: $size-l

    &__title
      margin-bottom: $size-s

      +mq-xs
        +flex-center
        flex-flow: column

      h5
        margin-bottom: $size-s
