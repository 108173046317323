@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"
















.assessments__list--empty
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  margin-top: $size-l
  overflow-x: hidden

  img
    height: 256px
    margin-bottom: $size-l

    +mq-xs
      width: 80%
      height: auto
      margin-bottom: $size-l

  p
    margin-top: $size-xxs
    margin-bottom: $size-m
    color: $color-ink-light

