@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"













































































































$card-height: 240px

.assessment-type-card
  display: flex
  flex-direction: column
  justify-content: space-between
  height: $card-height
  color: $color-white
  background-image: url("~@/assets/card-texture.svg") !important

  +mq-m
    margin-bottom: 10px

  +mq-s
    padding: 20px

  .card-grid
    +mq-s
      grid-template-columns: auto auto
      display: grid
      grid-gap: 10px !important

  .skeleton-custom-block
    +mq-s
      position: absolute

  &:hover
    cursor: pointer
    box-shadow: $shadow-l $color-ink-lighter

  &__icon
    width: $size-l
    height: $size-l

  &__title
    align-self: center

    +mq-l
      margin-top: 128px 0px 0px 0px

    +mq-s
      text-align: start

  .sas-skeleton-loader
    background: $color-primary

  .sas-skeleton-loader ~ .sas-skeleton-loader
    margin-top: $size-xs

    +mq-s
      display: inline
      align-self: center

